@font-face {
  font-family: "CutiveMono-Regular";
  src: url(./graphics/fonts/CutiveMono/CutiveMono-Regular.ttf);
  font-style: normal;
}

body {
  margin: 0;
  font-family: CutiveMono-Regular, 'Courier', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
  color:#212121
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  width: 100%;
  height: 65px;
  position: fixed;
  top: 0px;
  z-index: 1;
  background-color: #f7f7f7;
  color: #212121;
}

.footer {
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 0px;
  z-index: 0;
  background-color: #212121;
  color: #ffffff;
}

.marquee {
  width: 100%;
  text-align: center;
  position: fixed;
  top: 60px;
  z-index: 1;
  background-color: #212121;
  color: #ffffff;
}

.card {
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
  margin: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
}

.banner {
  background-color: #ffffff;
  border-radius:5px;
  text-align: left;
  margin: 15px;
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.25);
}

.itembox {
  background-color: #ddd;
  font-size: 48px;
  border-radius: 10px;
  border: #f7f7f7 solid 1px;
  text-align: left;
  margin: 5px;
  width: inherit;
  height: 105px;
}

.profilecard {
  color: #000000
}

.title {
  font-family: CutiveMono-Regular, 'Courier', serif;
}

.textinput {
  font-family: CutiveMono-Regular, 'Courier', serif;
  border-radius: 10px;
  border: solid 2px #212121;
  padding: 10px;
  margin: 5px;
}

.uploadbutton {
  font-family: CutiveMono-Regular, 'Courier', serif;
  background-color: #212121;
  color: #ffffff;
  border: 2px solid #212121;
  border-radius: 10px;
  width: 200px;
  height: 25px;
}

.uploadbutton:hover {
  background-color: #ffffff;
  color: #212121;
}

.connectbutton {
  font-family: CutiveMono-Regular, 'Courier', serif;
  background-color: #ffffff;
  color: #212121;
  border: 2px solid #212121;
  border-radius: 10px;
  width: 200px;
  height: 25px;
}

.active, .connectbutton:hover {
  background-color: #212121;
  color: #ffffff;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.message {
  color: #dc142b
}

.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0,0,0,0.5);
  display: flex;
}

.camera-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: #000000;
  display: flex;
}

.cover-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: #ffffff;
  display: flex;
}

.highlightable {
  opacity: 100%;
}

.highlightable:hover {
  opacity: 50%;
}

#browse-files {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

#browse-files-label {
  font-family: CutiveMono-Regular, 'Courier', serif;
  background-color: #212121;
  color: #ffffff;
  border: 2px solid #212121;
  border-radius: 10px;
  width: 84px;
  height: 84px;
}

#browse-files-label:hover {
  background-color: #ffffff;
  color: #212121;
}

